<template>
  <div class="container">
    <div class="row">
      <SpecialPageGridItem :cols="cols" type="cover" />
      <SpecialPageGridItem :cols="cols" type="dedication" />
      <SpecialPageGridItem :cols="cols" type="toc" />
      <SpecialPageGridItem :cols="cols" type="intro" />
      <SpecialPageGridItem :cols="cols" type="final" />
    </div>
  </div>
</template>
<script>
import SpecialPageGridItem from "./SpecialPageGridItem";

export default {
  components: {
    SpecialPageGridItem,
  },
  data() {
    return {
      loading: false,
    };
  },
  props: {
    cols: {
      type: Number,
      default: 6,
    },
  },
  computed: {
    hasDesigns() {
      return this.$root.designs === `1`;
    },
  },
  mounted() {
    this.$store.dispatch("loadDefaults", [
      [
        "default_font_family",
        "default_font_size",
        "default_page",
        "image_upload_limit",
        "image_upload_count",
      ],
      () => {
        this.loading = false;
        this.$store.dispatch("loadWritingsGroupPages", () => {});
      },
    ]);
  },
  methods: {},
};
</script>

<style scoped>
.draggable-grid {
  background: transparent !important;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
</style>